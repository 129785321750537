const state = {
  queues: {
    data: [],
    loading: false,
    orderDetail: {}
  },
  queue: {
    id: 0,
    status: "preparing",
    ticket_name: "Q...",
    customer_name: "",
    customer_id: "",
    order: "",
    vendorLogo: "",
    vendor_id: 0
  }
};

const getters = {
  allQueueData(state) {
    return state.queues.data;
  },
  singleQueueData(state) {
    return state.queue;
  },
  isLoading(state) {
    return state.queues.loading;
  },
  orderDetail(state) {
    return state.queues.orderDetail;
  }
};
const mutations = {
  setQueueData(state, data) {
    state.queues.data = data;
  },
  setSingleQueueData(state) {
    let user = JSON.parse(localStorage.getItem("userId"));
    let filteredData = state.queues.data.find(queue => queue.id === user.id);
    state.queue = filteredData;
  },
  setLoading(state, data) {
    state.queues.loading = data;
  },
  setIndividualQueueData(state, data) {
    state.queue = { ...state.queue, ...data };
  },
  setOrderDetail(state, data) {
    state.queues.orderDetail = data;
  }
};
const actions = {
  fetchQueueData({ commit }, data) {
    commit("setLoading", true);
    this.$http
      .get(`vendor/profile/${data.vendor_id}`)
      .then(response => {
        let profile = response.data.data[0];
        let vendorLogo = { vendorLogo: profile.logo };
        commit(
          "setIndividualQueueData",
          process.env.VUE_APP_BASE_URL + vendorLogo
        );
        localStorage.setItem(
          "vendorLogo",
          JSON.stringify(process.env.VUE_APP_BASE_URL + profile.logo)
        );
        let newTicket = {
          vendor_id: data.vendor_id,
          status: "preparing"
        };
        this.$http.post("vendor/queues", newTicket).then(res => {
          let ticket = res.data.data;
          let ticket_name =
            ticket.ticket_num < 10
              ? "Q0" + ticket.ticket_num
              : "Q" + ticket.ticket_num;
          let payload = {
            vendor_id: ticket.vendor_id,
            id: ticket.id,
            status: ticket.status,
            ticket_name: ticket_name
          };
          localStorage.setItem("ticketId", JSON.stringify(ticket.id));
          commit("setIndividualQueueData", payload);
        });
        console.log(response.data.data);
        commit("setLoading", false);
      })
      .catch(error => {
        // let errorMessage = error.message;
        console.error("There was an error!", error);
        commit("setLoading", false);
      });
  },
  fetchAllQueues({ commit }, data) {
    commit("setLoading", true);
    this.$http
      .get(`user/queues/${data.userId}`)
      .then(response => {
        console.log(response.data.data);
        let queue = response.data.data[0];
        let ticket_name =
          queue.ticket_num < 10
            ? "Q0" + queue.ticket_num
            : "Q" + queue.ticket_num;

        let payload = {
          customer_name: queue.customer_name,
          customer_id: queue.customer_id,
          vendor_id: queue.vendor_id,
          id: queue.id,
          status: queue.status,
          ticket_name: ticket_name
        };
        commit("setIndividualQueueData", payload);
        commit("setLoading", false);
      })
      .catch(error => {
        // let errorMessage = error.message;
        console.error("There was an error!", error);
        commit("setLoading", false);
      });
  },
  fetchTicket({ commit, dispatch }, data) {
    commit("setLoading", true);
    this.$http
      .get(`vendor/queues/${data.ticket}/ticket`)
      .then(response => {
        let ticket = response.data;
        if (ticket.status !== "preparing") {
          localStorage.removeItem("ticketId");
          localStorage.removeItem("vendorLogo");
          let payload = { vendor_id: data.vendor_id };
          dispatch("fetchQueueData", payload);
        } else {
          let ticket_name =
            ticket.ticket_num < 10
              ? "Q0" + ticket.ticket_num
              : "Q" + ticket.ticket_num;
          let payload = {
            vendor_id: ticket.vendor_id,
            id: ticket.id,
            status: ticket.status,
            ticket_name: ticket_name,
            vendorLogo: JSON.parse(localStorage.getItem("vendorLogo"))
          };
          commit("setIndividualQueueData", payload);
        }
        commit("setLoading", false);
      })
      .catch(error => {
        // let errorMessage = error.message;
        console.error("There was an error!", error);
        commit("setLoading", false);
      });
  },
  onPageLoad({ dispatch, state }, data) {
    let ticket = {
      ticket: JSON.parse(localStorage.getItem("ticketId")),
      ...data
    };
    let vendor = data;
    if (ticket.ticket !== null) {
      if (state.queue.id === 0) {
        dispatch("fetchTicket", ticket);
      }
    } else {
      dispatch("fetchQueueData", vendor);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
