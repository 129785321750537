const state = {
  input: {
    queueScreenData: {
      venueName: "",
      category: "",
      message: "",
      estimatedTime: false
    },
    loading: false,
    isDisabled: {
      venueName: true,
      category: true,
      message: true,
      ready: true
    }
  }
};

const getters = {
  inputData(state) {
    return state.input.queueScreenData;
  },
  isLoading(state) {
    return state.input.loading;
  },
  isDisabled(state) {
    return state.input.isDisabled;
  }
};
const mutations = {
  setInputData(state, data) {
    state.input.queueScreenData[data.input] = data.value;
  },
  setVenueName(state, data) {
    state.input.queueScreenData.venueName = data;
  },
  setCategory(state, data) {
    state.input.queueScreenData.category = data;
  },
  setMessage(state, data) {
    state.input.queueScreenData.message = data;
  },
  setEstimatedTime(state, data) {
    state.input.queueScreenData.estimatedTime = data;
  },
  setLoading(state, data) {
    state.input.loading = data;
  },
  setDisabledVenueName(state, data) {
    state.input.isDisabled.venueName = data;
  },
  setDisabledCategory(state, data) {
    state.input.isDisabled.category = data;
  },
  setDisabledMessage(state, data) {
    state.input.isDisabled.message = data;
  },
  setDisabledReadyMessage(state, data) {
    state.input.isDisabled.ready = data;
  }
};
const actions = {
  fetchQueueInitialData({ commit }) {
    commit("setLoading", true);
    let user = JSON.parse(localStorage.getItem("userData"));
    return new Promise((resolve, reject) => {
      this.$http
        .get("vendor/settings/queues/" + user.id)
        .then(response => {
          response.data.data.forEach(data => {
            let key = Object.keys(data);
            switch (key[0]) {
              case "venue_name":
                commit("setVenueName", data.venue_name);
                break;
              case "category":
                commit("setCategory", data.category);
                break;
              case "message":
                commit("setMessage", data.message);
                break;
            }
          });
          commit("setLoading", false);
          resolve();
        })
        .catch(error => {
          let errorMessage = error.message;
          console.error("There was an error!", error);
          commit("setLoading", false);
          reject(errorMessage);
        });
    });
  },
  updateQueueData({ commit, dispatch }, updateDetails) {
    commit("setLoading", true);
    let body = updateDetails;
    let user = JSON.parse(localStorage.getItem("userData"));
    return new Promise((resolve, reject) => {
      this.$http
        .put("vendor/settings/" + user.id, body)
        .then(response => {
          console.log(response.data);
          commit("setLoading", false);
          resolve();
          dispatch("fetchQueueInitialData");
        })
        .catch(error => {
          let errorMessage = error.message;
          console.error("There was an error!", error);
          commit("setLoading", false);
          reject(errorMessage);
        });
    });
  },
  setEnableInput({ commit }, inputType) {
    let payload = false;
    switch (inputType) {
      case "venueName":
        commit("setDisabledVenueName", payload);
        break;
      case "category":
        commit("setDisabledCategory", payload);
        break;
      case "message":
        commit("setDisabledMessage", payload);
        break;
      case "ready":
        commit("setDisabledReadyMessage", payload);
        break;
      default:
        console.log(inputType + "doesn't belong to any cases");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
