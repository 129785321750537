import moment from "moment";

const state = {
  data: {
    exportModal: false,
    aveScan: 0,
    aveScanText: "",
    newCustomerPercentage: 0,
    returningCustomerPercentage: 0,
    scanPercentage: 0,
    scanActiveTab: 1,
    waitActiveTab: 1,
    chartLabels: ["00:00", "06:00", "12:00", "18:00"],
    waitTimeChartLabels: ["00:00", "06:00", "12:00", "18:00"],
    scanData: [],
    waitTimeData: [],
    minutes: 0,
    isLoading: false
  }
};

const getters = {
  exportModal(state) {
    return state.data.exportModal;
  },
  aveScan(state) {
    return state.data.aveScan;
  },
  aveScanText(state) {
    return state.data.aveScanText;
  },
  newCustomerPercentage(state) {
    return state.data.newCustomerPercentage;
  },
  returnCustomerPercentage(state) {
    return state.data.returningCustomerPercentage;
  },
  scanPercentage(state) {
    return state.data.scanPercentage;
  },
  scanActiveTab(state) {
    return state.data.scanActiveTab;
  },
  waitActiveTab(state) {
    return state.data.waitActiveTab;
  },
  chartLabels(state) {
    return state.data.chartLabels;
  },
  waitTimeChartLabels(state) {
    return state.data.waitTimeChartLabels;
  },
  scanData(state) {
    return state.data.scanData;
  },
  waitTimeData(state) {
    return state.data.waitTimeData;
  },
  minutes(state) {
    return state.data.minutes;
  },
  isLoading(state) {
    return state.data.loading;
  }
};
const mutations = {
  setExportModal(state, data) {
    state.data.exportModal = data;
  },
  setAveScan(state, data) {
    state.data.aveScan = data;
  },
  setAveScanText(state, data) {
    state.data.aveScanText = data;
  },
  setNewCustomerPercentage(state, data) {
    state.data.newCustomerPercentage = data;
  },
  setReturnCustomerPercentage(state, data) {
    state.data.returningCustomerPercentage = data;
  },
  setScanPercentage(state, data) {
    state.data.scanPercentage = data;
  },
  setScanActiveTab(state, data) {
    state.data.scanActiveTab = data;
  },
  setWaitActiveTab(state, data) {
    state.data.waitActiveTab = data;
  },
  setChartLabels(state, data) {
    state.data.chartLabels = data;
  },
  setWaitTimeChartLabels(state, data) {
    state.data.waitTimeChartLabels = data;
  },
  setScanData(state, data) {
    state.data.scanData = data;
  },
  setWaitTimeData(state, data) {
    state.data.waitTimeData = data;
  },
  setMinutes(state, data) {
    state.data.minutes = data;
  },
  setLoading(state, data) {
    state.data.loading = data;
  }
};
const actions = {
  fetchStatisticsScanData({ commit, dispatch }, tab) {
    commit("setLoading", true);
    let body = { stat: tab };
    let user = JSON.parse(localStorage.getItem("userData"));
    return new Promise((resolve, reject) => {
      this.$http
        .get("console/get/statics?require_for=" + tab + "&waiting_stats=0")
        .then(response => {
          switch (tab) {
            case "today": {
              commit("setScanActiveTab", 1);
              let between = [
                { from: 0, to: 1 },
                { from: 1, to: 2 },
                { from: 2, to: 3 },
                { from: 3, to: 4 },
                { from: 4, to: 5 },
                { from: 5, to: 6 },
                { from: 6, to: 7 },
                { from: 7, to: 8 },
                { from: 8, to: 9 },
                { from: 9, to: 10 },
                { from: 10, to: 11 },
                { from: 11, to: 12 },
                { from: 12, to: 13 },
                { from: 13, to: 14 },
                { from: 14, to: 15 },
                { from: 15, to: 16 },
                { from: 16, to: 17 },
                { from: 17, to: 18 },
                { from: 18, to: 19 },
                { from: 19, to: 20 },
                { from: 20, to: 21 },
                { from: 21, to: 22 },
                { from: 22, to: 23 },
                { from: 23, to: 24 },
              ];
              let payload = {
                between: between,
                data: response.data,
                type: "scan"
              };
              let newPercentage = response.data.user_stats.new
                ? (response.data.user_stats.new /
                  (response.data.user_stats.new +
                    response.data.user_stats.returning)) *
                100
                : 0;
              let returnPercentage = response.data.user_stats.returning
                ? (response.data.user_stats.returning /
                  (response.data.user_stats.new +
                    response.data.user_stats.returning)) *
                100
                : 0;
              commit("setAveScanText", "scans/hr");
              commit("setNewCustomerPercentage", parseInt(newPercentage));
              commit("setReturnCustomerPercentage", parseInt(returnPercentage));
              commit("setChartLabels", ["00-01", "01-02", "02-03", "03-04", "04-05", "05-06", "06-07", "07-08", "08-09", "09-10", "10-11", "11-12", "12-13", "13-14", "14-15", "15-16", "16-17", "17-18", "18-19", "19-20", "20-21", "21-22", "22-23", "23:00-23:59"]);
              // commit("setChartLabels", ["00:00", "01:00", "02:00" ,"03:00"  ,"04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"]);
              dispatch("countByHour", payload);
              commit("setLoading", false);
              resolve();
              break;
            }
            case "last_7days": {
              commit("setScanActiveTab", 2);
              let between = [
                {
                  from: moment()
                    .subtract(7, "days")
                    .format(),
                  to: moment()
                    .subtract(6, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(6, "days")
                    .format(),
                  to: moment()
                    .subtract(5, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(5, "days")
                    .format(),
                  to: moment()
                    .subtract(4, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(4, "days")
                    .format(),
                  to: moment()
                    .subtract(3, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(3, "days")
                    .format(),
                  to: moment()
                    .subtract(2, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(2, "days")
                    .format(),
                  to: moment()
                    .subtract(1, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(1, "days")
                    .format(),
                  to: moment().format()
                }
              ];
              let payload = {
                between: between,
                data: response.data,
                type: "scan"
              };
              let newPercentage = response.data.user_stats.new
                ? (response.data.user_stats.new /
                  (response.data.user_stats.new +
                    response.data.user_stats.returning)) *
                100
                : 0;
              let returnPercentage = response.data.user_stats.returning
                ? (response.data.user_stats.returning /
                  (response.data.user_stats.new +
                    response.data.user_stats.returning)) *
                100
                : 0;

              commit("setAveScanText", "scans/day");
              commit("setNewCustomerPercentage", parseInt(newPercentage));
              commit("setReturnCustomerPercentage", parseInt(returnPercentage));
              commit("setChartLabels", [
                // moment()
                //   .subtract(7, "days")
                //   .format("MMM DD"),
                moment()
                  .subtract(6, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(5, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(4, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(3, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(2, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(1, "days")
                  .format("MMM DD"),
                moment().format("MMM DD")
              ]);
              // dispatch("countByDay", payload);
              dispatch("countByADay", payload);
              commit("setLoading", false);
              resolve();
              break;
            }
            case "last_30days": {
              commit("setScanActiveTab", 3);

              let between = [
                {
                  from: moment()
                    .subtract(30, "days")
                    .format(),
                  to: moment()
                    .subtract(21, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(21, "days")
                    .format(),
                  to: moment()
                    .subtract(14, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(14, "days")
                    .format(),
                  to: moment()
                    .subtract(7, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(7, "days")
                    .format(),
                  to: moment().format()
                }
              ];
              let payload = {
                between: between,
                data: response.data,
                type: "scan"
              };
              let newPercentage = response.data.user_stats.new
                ? (response.data.user_stats.new /
                  (response.data.user_stats.new +
                    response.data.user_stats.returning)) *
                100
                : 0;
              let returnPercentage = response.data.user_stats.returning
                ? (response.data.user_stats.returning /
                  (response.data.user_stats.new +
                    response.data.user_stats.returning)) *
                100
                : 0;
              commit("setAveScanText", "scans/week");
              commit("setNewCustomerPercentage", parseInt(newPercentage));
              commit("setReturnCustomerPercentage", parseInt(returnPercentage));
              // commit("setChartLabels", [
              //   moment()
              //     .subtract(21, "days")
              //     .format("MMM DD"),
              //   moment()
              //     .subtract(14, "days")
              //     .format("MMM DD"),
              //   moment()
              //     .subtract(7, "days")
              //     .format("MMM DD"),
              //   moment().format("MMM DD")
              // ]);
              commit("setChartLabels", [
                moment()
                  .subtract(30, "days")
                  .format("MMM DD") + "-" +
                moment()
                  .subtract(21, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(21, "days")
                  .format("MMM DD") + "-" +
                moment()
                  .subtract(14, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(14, "days")
                  .format("MMM DD") + "-" +
                moment()
                  .subtract(7, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(7, "days")
                  .format("MMM DD") + "-" +
                moment().format("MMM DD")
              ]);
              dispatch("countByDay", payload);
              commit("setLoading", false);
              resolve();
              break;
            }
            case "last_90days": {
              commit("setScanActiveTab", 4);

              // let between = [
              //   {
              //     from: moment()
              //       .subtract(90, "days")
              //       .format(),
              //     to: moment()
              //       .subtract(75, "days")
              //       .format()
              //   },
              //   {
              //     from: moment()
              //       .subtract(75, "days")
              //       .format(),
              //     to: moment()
              //       .subtract(60, "days")
              //       .format()
              //   },
              //   {
              //     from: moment()
              //       .subtract(60, "days")
              //       .format(),
              //     to: moment()
              //       .subtract(45, "days")
              //       .format()
              //   },
              //   {
              //     from: moment()
              //       .subtract(45, "days")
              //       .format(),
              //     to: moment()
              //       .subtract(30, "days")
              //       .format()
              //   },
              //   {
              //     from: moment()
              //       .subtract(30, "days")
              //       .format(),
              //     to: moment()
              //       .subtract(15, "days")
              //       .format()
              //   },
              //   {
              //     from: moment()
              //       .subtract(15, "days")
              //       .format(),
              //     to: moment().format()
              //   }
              // ];
              let between = [
                {
                  from: moment()
                    .subtract(90, "days")
                    .format(),
                  to: moment()
                    .subtract(60, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(60, "days")
                    .format(),
                  to: moment()
                    .subtract(30, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(30, "days")
                    .format(),
                  to: moment()
                    .format()
                },

              ];
              let payload = {
                between: between,
                data: response.data,
                type: "scan"
              };
              let newPercentage = response.data.user_stats.new
                ? (response.data.user_stats.new /
                  (response.data.user_stats.new +
                    response.data.user_stats.returning)) *
                100
                : 0;
              let returnPercentage = response.data.user_stats.returning
                ? (response.data.user_stats.returning /
                  (response.data.user_stats.new +
                    response.data.user_stats.returning)) *
                100
                : 0;
              commit("setAveScanText", "scans/month");
              commit("setNewCustomerPercentage", parseInt(newPercentage));
              commit("setReturnCustomerPercentage", parseInt(returnPercentage));
              commit("setChartLabels", [
                moment()
                  .subtract(90, "days")
                  .format("MMM DD") + "-" +
                moment()
                  .subtract(60, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(60, "days")
                  .format("MMM DD") + "-" +
                moment()
                  .subtract(30, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(30, "days")
                  .format("MMM DD") + "-" +
                moment().format("MMM DD")
              ]);
              // commit("setChartLabels", [
              //   moment()
              //     .subtract(75, "days")
              //     .format("MMM DD"),
              //   moment()
              //     .subtract(60, "days")
              //     .format("MMM DD"),
              //   moment()
              //     .subtract(45, "days")
              //     .format("MMM DD"),
              //   moment()
              //     .subtract(30, "days")
              //     .format("MMM DD"),
              //   moment()
              //     .subtract(15, "days")
              //     .format("MMM DD"),
              //   moment().format("MMM DD")
              // ]);
              dispatch("countByDay", payload);
              commit("setLoading", false);
              resolve();
              break;
            }
            case "this_year": {
              commit("setScanActiveTab", 5);
              let between = [
                {
                  range: moment()
                    .month(0)
                    .format()
                },
                {
                  range: moment()
                    .month(1)
                    .format()
                },
                {
                  range: moment()
                    .month(2)
                    .format()
                },
                {
                  range: moment()
                    .month(3)
                    .format()
                },
                {
                  range: moment()
                    .month(4)
                    .format()
                },
                {
                  range: moment()
                    .month(5)
                    .format()
                },
                {
                  range: moment()
                    .month(6)
                    .format()
                },
                {
                  range: moment()
                    .month(7)
                    .format()
                },
                {
                  range: moment()
                    .month(8)
                    .format()
                },
                {
                  range: moment()
                    .month(9)
                    .format()
                },
                {
                  range: moment()
                    .month(10)
                    .format()
                },
                {
                  range: moment()
                    .month(11)
                    .format()
                }
              ];
              let payload = {
                between: between,
                data: response.data,
                set: "month",
                type: "scan"
              };
              let newPercentage = response.data.user_stats.new
                ? (response.data.user_stats.new /
                  (response.data.user_stats.new +
                    response.data.user_stats.returning)) *
                100
                : 0;
              let returnPercentage = response.data.user_stats.returning
                ? (response.data.user_stats.returning /
                  (response.data.user_stats.new +
                    response.data.user_stats.returning)) *
                100
                : 0;
              commit("setAveScanText", "scans/month");
              commit("setNewCustomerPercentage", parseInt(newPercentage));
              commit("setReturnCustomerPercentage", parseInt(returnPercentage));
              commit("setChartLabels", [
                moment()
                  .month(0)
                  .format("MMM"),
                moment()
                  .month(1)
                  .format("MMM"),
                moment()
                  .month(2)
                  .format("MMM"),
                moment()
                  .month(3)
                  .format("MMM"),
                moment()
                  .month(4)
                  .format("MMM"),
                moment()
                  .month(5)
                  .format("MMM"),
                moment()
                  .month(6)
                  .format("MMM"),
                moment()
                  .month(7)
                  .format("MMM"),
                moment()
                  .month(8)
                  .format("MMM"),
                moment()
                  .month(9)
                  .format("MMM"),
                moment()
                  .month(10)
                  .format("MMM"),
                moment()
                  .month(11)
                  .format("MMM")
              ]);
              dispatch("countByWhole", payload);
              commit("setLoading", false);
              resolve();
              break;
            }
            case "all_stats": {
              commit("setScanActiveTab", 6);
              let between = [
                {
                  range: moment()
                    .subtract(3, "year")
                    .format()
                },
                {
                  range: moment()
                    .subtract(2, "year")
                    .format()
                },
                {
                  range: moment()
                    .subtract(1, "year")
                    .format()
                },
                {
                  range: moment().format()
                }
              ];
              let payload = {
                between: between,
                data: response.data,
                set: "year",
                type: "scan"
              };
              let newPercentage = response.data.user_stats.new
                ? (response.data.user_stats.new /
                  (response.data.user_stats.new +
                    response.data.user_stats.returning)) *
                100
                : 0;
              let returnPercentage = response.data.user_stats.returning
                ? (response.data.user_stats.returning /
                  (response.data.user_stats.new +
                    response.data.user_stats.returning)) *
                100
                : 0;
              commit("setAveScanText", "scans/year");
              commit("setNewCustomerPercentage", parseInt(newPercentage));
              commit("setReturnCustomerPercentage", parseInt(returnPercentage));
              commit("setChartLabels", [
                moment()
                  .subtract(3, "year")
                  .format("YYYY"),
                moment()
                  .subtract(2, "year")
                  .format("YYYY"),
                moment()
                  .subtract(1, "year")
                  .format("YYYY"),
                moment().format("YYYY")
              ]);
              dispatch("countByWhole", payload);
              commit("setLoading", false);
              resolve();
              break;
            }
          }
        })
        .catch(error => {
          let errorMessage = error.message;
          console.error("There was an error!", error);
          commit("setLoading", false);
          reject(errorMessage);
        });
    });
  },
  fetchStatisticsWaitTimeData({ commit, dispatch }, tab) {
    commit("setLoading", true);
    let body = { stat: tab };
    let user = JSON.parse(localStorage.getItem("userData"));
    return new Promise((resolve, reject) => {
      this.$http
        .get("console/get/statics?require_for=" + tab + "&waiting_stats=1")
        .then(response => {
          switch (tab) {
            case "today": {
              commit("setWaitActiveTab", 1);
              let between = [
                { from: 0, to: 1 },
                { from: 1, to: 2 },
                { from: 2, to: 3 },
                { from: 3, to: 4 },
                { from: 4, to: 5 },
                { from: 5, to: 6 },
                { from: 6, to: 7 },
                { from: 7, to: 8 },
                { from: 8, to: 9 },
                { from: 9, to: 10 },
                { from: 10, to: 11 },
                { from: 11, to: 12 },
                { from: 12, to: 13 },
                { from: 13, to: 14 },
                { from: 14, to: 15 },
                { from: 15, to: 16 },
                { from: 16, to: 17 },
                { from: 17, to: 18 },
                { from: 18, to: 19 },
                { from: 19, to: 20 },
                { from: 20, to: 21 },
                { from: 21, to: 22 },
                { from: 22, to: 23 },
                { from: 23, to: 24 },
              ];
              let payload = {
                between: between,
                data: response.data,
                type: "waitTime"
              };

              // commit("setWaitTimeChartLabels", ["00:00", "01:00", "02:00" ,"03:00"  ,"04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"]);
              commit("setWaitTimeChartLabels", ["00-01", "01-02", "02-03", "03-04", "04-05", "05-06", "06-07", "07-08", "08-09", "09-10", "10-11", "11-12", "12-13", "13-14", "14-15", "15-16", "16-17", "17-18", "18-19", "19-20", "20-21", "21-22", "22-23", "23:00-23:59"]);
              dispatch("countByHour", payload);
              commit("setLoading", false);
              resolve();
              break;
            }
            case "last_7days": {
              commit("setWaitActiveTab", 2);
              let between = [
                {
                  from: moment()
                    .subtract(7, "days")
                    .format(),
                  to: moment()
                    .subtract(6, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(6, "days")
                    .format(),
                  to: moment()
                    .subtract(5, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(5, "days")
                    .format(),
                  to: moment()
                    .subtract(4, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(4, "days")
                    .format(),
                  to: moment()
                    .subtract(3, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(3, "days")
                    .format(),
                  to: moment()
                    .subtract(2, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(2, "days")
                    .format(),
                  to: moment()
                    .subtract(1, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(1, "days")
                    .format(),
                  to: moment().format()
                }
              ];
              let payload = {
                between: between,
                data: response.data,
                type: "waitTime"
              };
              commit("setWaitTimeChartLabels", [
                // moment()
                //   .subtract(7, "days")
                //   .format("MMM DD"),
                moment()
                  .subtract(6, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(5, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(4, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(3, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(2, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(1, "days")
                  .format("MMM DD"),
                moment().format("MMM DD")
              ]);
              // dispatch("countByDay", payload);
              dispatch("countByADay", payload);
              commit("setLoading", false);
              resolve();
              break;
            }
            case "last_30days": {
              commit("setWaitActiveTab", 3);

              let between = [
                {
                  from: moment()
                    .subtract(30, "days")
                    .format(),
                  to: moment()
                    .subtract(21, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(21, "days")
                    .format(),
                  to: moment()
                    .subtract(14, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(14, "days")
                    .format(),
                  to: moment()
                    .subtract(7, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(7, "days")
                    .format(),
                  to: moment().format()
                }
              ];
              let payload = {
                between: between,
                data: response.data,
                type: "waitTime"
              };
              // commit("setWaitTimeChartLabels", [
              //   moment()
              //     .subtract(21, "days")
              //     .format("MMM DD"),
              //   moment()
              //     .subtract(14, "days")
              //     .format("MMM DD"),
              //   moment()
              //     .subtract(7, "days")
              //     .format("MMM DD"),
              //   moment().format("MMM DD")
              // ]);
              commit("setWaitTimeChartLabels", [
                moment()
                  .subtract(30, "days")
                  .format("MMM DD") + "-" +
                moment()
                  .subtract(21, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(21, "days")
                  .format("MMM DD") + "-" +
                moment()
                  .subtract(14, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(14, "days")
                  .format("MMM DD") + "-" +
                moment()
                  .subtract(7, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(7, "days")
                  .format("MMM DD") + "-" +
                moment().format("MMM DD")
              ]);
              dispatch("countByDay", payload);
              commit("setLoading", false);
              resolve();
              break;
            }
            case "last_90days": {
              commit("setWaitActiveTab", 4);

              // let between = [
              //   {
              //     from: moment()
              //       .subtract(90, "days")
              //       .format(),
              //     to: moment()
              //       .subtract(75, "days")
              //       .format()
              //   },
              //   {
              //     from: moment()
              //       .subtract(75, "days")
              //       .format(),
              //     to: moment()
              //       .subtract(60, "days")
              //       .format()
              //   },
              //   {
              //     from: moment()
              //       .subtract(60, "days")
              //       .format(),
              //     to: moment()
              //       .subtract(45, "days")
              //       .format()
              //   },
              //   {
              //     from: moment()
              //       .subtract(45, "days")
              //       .format(),
              //     to: moment()
              //       .subtract(30, "days")
              //       .format()
              //   },
              //   {
              //     from: moment()
              //       .subtract(30, "days")
              //       .format(),
              //     to: moment()
              //       .subtract(15, "days")
              //       .format()
              //   },
              //   {
              //     from: moment()
              //       .subtract(15, "days")
              //       .format(),
              //     to: moment().format()
              //   }
              // ];
              let between = [
                {
                  from: moment()
                    .subtract(90, "days")
                    .format(),
                  to: moment()
                    .subtract(60, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(60, "days")
                    .format(),
                  to: moment()
                    .subtract(30, "days")
                    .format()
                },
                {
                  from: moment()
                    .subtract(30, "days")
                    .format(),
                  to: moment()
                    .format()
                },

              ];
              let payload = {
                between: between,
                data: response.data,
                type: "waitTime"
              };
              // commit("setWaitTimeChartLabels", [
              //   moment()
              //     .subtract(75, "days")
              //     .format("MMM DD"),
              //   moment()
              //     .subtract(60, "days")
              //     .format("MMM DD"),
              //   moment()
              //     .subtract(45, "days")
              //     .format("MMM DD"),
              //   moment()
              //     .subtract(30, "days")
              //     .format("MMM DD"),
              //   moment()
              //     .subtract(15, "days")
              //     .format("MMM DD"),
              //   moment().format("MMM DD")
              // ]);
              commit("setWaitTimeChartLabels", [
                moment()
                  .subtract(90, "days")
                  .format("MMM DD") + "-" +
                moment()
                  .subtract(60, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(60, "days")
                  .format("MMM DD") + "-" +
                moment()
                  .subtract(30, "days")
                  .format("MMM DD"),
                moment()
                  .subtract(30, "days")
                  .format("MMM DD") + "-" +
                moment().format("MMM DD")
              ]);
              dispatch("countByDay", payload);
              commit("setLoading", false);
              resolve();
              break;
            }
            case "this_year": {
              commit("setWaitActiveTab", 5);
              let between = [
                {
                  range: moment()
                    .month(0)
                    .format()
                },
                {
                  range: moment()
                    .month(1)
                    .format()
                },
                {
                  range: moment()
                    .month(2)
                    .format()
                },
                {
                  range: moment()
                    .month(3)
                    .format()
                },
                {
                  range: moment()
                    .month(4)
                    .format()
                },
                {
                  range: moment()
                    .month(5)
                    .format()
                },
                {
                  range: moment()
                    .month(6)
                    .format()
                },
                {
                  range: moment()
                    .month(7)
                    .format()
                },
                {
                  range: moment()
                    .month(8)
                    .format()
                },
                {
                  range: moment()
                    .month(9)
                    .format()
                },
                {
                  range: moment()
                    .month(10)
                    .format()
                },
                {
                  range: moment()
                    .month(11)
                    .format()
                }
              ];
              let payload = {
                between: between,
                data: response.data,
                set: "month",
                type: "waitTime"
              };
              commit("setWaitTimeChartLabels", [
                moment()
                  .month(0)
                  .format("MMM"),
                moment()
                  .month(1)
                  .format("MMM"),
                moment()
                  .month(2)
                  .format("MMM"),
                moment()
                  .month(3)
                  .format("MMM"),
                moment()
                  .month(4)
                  .format("MMM"),
                moment()
                  .month(5)
                  .format("MMM"),
                moment()
                  .month(6)
                  .format("MMM"),
                moment()
                  .month(7)
                  .format("MMM"),
                moment()
                  .month(8)
                  .format("MMM"),
                moment()
                  .month(9)
                  .format("MMM"),
                moment()
                  .month(10)
                  .format("MMM"),
                moment()
                  .month(11)
                  .format("MMM")
              ]);
              dispatch("countByWhole", payload);
              commit("setLoading", false);
              resolve();
              break;
            }
            case "all_stats": {
              commit("setWaitActiveTab", 6);
              let between = [
                {
                  range: moment()
                    .subtract(3, "year")
                    .format()
                },
                {
                  range: moment()
                    .subtract(2, "year")
                    .format()
                },
                {
                  range: moment()
                    .subtract(1, "year")
                    .format()
                },
                {
                  range: moment().format()
                }
              ];
              let payload = {
                between: between,
                data: response.data,
                set: "year",
                type: "waitTime"
              };
              commit("setWaitTimeChartLabels", [
                moment()
                  .subtract(3, "year")
                  .format("YYYY"),
                moment()
                  .subtract(2, "year")
                  .format("YYYY"),
                moment()
                  .subtract(1, "year")
                  .format("YYYY"),
                moment().format("YYYY")
              ]);
              dispatch("countByWhole", payload);
              commit("setLoading", false);
              resolve();
              break;
            }
          }
        })
        .catch(error => {
          let errorMessage = error.message;
          console.error("There was an error!", error);
          commit("setLoading", false);
          reject(errorMessage);
        });
    });
  },
  countByHour({ commit }, payload) {
    let countScannedData = [];
    let waitTimeData = [];

    payload.between.forEach(data => {
      let from = moment().set({ hour: data.from, minutes: 0, seconds: 0 });
      let to = moment().set({ hour: data.to, minutes: 0, seconds: 0 });
      let dataFiltered = payload.data.queues;
      let waitTimeAvg = 0;

      dataFiltered = dataFiltered.filter(data => {
        if (data.logs && data.logs.length) {
          let preparingLog = data.logs.find(e => e.order_status === "Preparing");
          if (preparingLog) {
            return moment(preparingLog.created_at).isBetween(from._d, to._d);
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
      if (payload.type === "scan") {
        countScannedData = [...countScannedData, dataFiltered.length];
      }

      if (payload.type === "waitTime") {
        let waitTimeTotal = 0;
        let dateDiff = 0;
        dataFiltered.forEach(data => {
          if (data.logs && data.logs.length) {
            let preparingLog = data.logs.find(e => e.order_status === "Preparing");
            let ReadyLog = data.logs.find(e => e.order_status === "Ready");
            if (preparingLog && ReadyLog) {
              dateDiff = moment(ReadyLog.created_at).diff(
                moment(preparingLog.created_at),
                "minutes"
              );
              waitTimeTotal = waitTimeTotal + dateDiff;
            }
          }

        });
        waitTimeAvg = waitTimeTotal ? waitTimeTotal / dataFiltered.length : 0;
        waitTimeData = [...waitTimeData, waitTimeAvg];
      }
    });

    if (payload.type === "scan") {
      commit("setScanData", countScannedData);
      let sum = countScannedData.reduce((a, b) => a + b, 0);
      let avg = parseFloat(sum / countScannedData.length).toFixed(2);
      commit("setAveScan", avg);
      let percentage = (avg / sum) * 100;
      percentage = percentage > 0 ? percentage : 0;
      commit("setScanPercentage", percentage);
    }

    if (payload.type === "waitTime") {
      commit("setWaitTimeData", waitTimeData);
      let sum = waitTimeData.reduce((a, b) => a + b, 0);
      let avg = parseFloat(sum / waitTimeData.length).toFixed(2);
      commit("setMinutes", avg);
    }
  },
  countByDay({ commit }, payload) {
    let countScannedData = [];
    let waitTimeData = [];

    payload.between.forEach(data => {
      let from = data.from;
      let to = data.to;
      let dataFiltered = payload.data.queues;
      let waitTimeAvg = 0;

      dataFiltered = dataFiltered.filter(data => {
        if (data.logs && data.logs.length) {
          let preparingLog = data.logs.find(e => e.order_status === "Preparing");
          if (preparingLog) {
            return moment(preparingLog.created_at).isBetween(from, to);
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
      if (payload.type === "scan") {
        countScannedData = [...countScannedData, dataFiltered.length];
      }
      if (payload.type === "waitTime") {
        let waitTimeTotal = 0;
        let dateDiff = 0;
        dataFiltered.forEach(data => {
          if (data.logs && data.logs.length) {
            let preparingLog = data.logs.find(e => e.order_status === "Preparing");
            let ReadyLog = data.logs.find(e => e.order_status === "Ready");
            if (preparingLog && ReadyLog) {
              dateDiff = moment(ReadyLog.created_at).diff(
                moment(preparingLog.created_at),
                "minutes"
              );
              waitTimeTotal = waitTimeTotal + dateDiff;
            }
          }
        });
        waitTimeAvg = waitTimeTotal ? waitTimeTotal / dataFiltered.length : 0;
        waitTimeData = [...waitTimeData, waitTimeAvg];
      }
    });
    if (payload.type === "scan") {
      commit("setScanData", countScannedData);
      let sum = countScannedData.reduce((a, b) => a + b, 0);
      let avg = parseFloat(sum / countScannedData.length).toFixed(2);
      commit("setAveScan", avg);
      let percentage = (avg / sum) * 100;
      percentage = percentage > 0 ? percentage : 0;
      commit("setScanPercentage", percentage);
    }

    if (payload.type === "waitTime") {
      commit("setWaitTimeData", waitTimeData);
      let sum = waitTimeData.reduce((a, b) => a + b, 0);
      let avg = parseFloat(sum / waitTimeData.length).toFixed(2);
      commit("setMinutes", avg);
    }
  },
  countByWhole({ commit }, payload) {
    let countScannedData = [];
    let waitTimeData = [];

    payload.between.forEach(data => {
      let range = data.range;
      let set = payload.set;
      let dataFiltered = payload.data.queues;
      let waitTimeAvg = 0;

      dataFiltered = dataFiltered.filter(data => {
        if (data.logs && data.logs.length) {
          let preparingLog = data.logs.find(e => e.order_status === "Preparing");
          if (preparingLog) {
            return moment(preparingLog.created_at).isSame(range, set);
          } else {
            return false;
          }
        } else {
          return false;
        }
      });

      if (payload.type === "scan") {
        countScannedData = [...countScannedData, dataFiltered.length];
      }

      if (payload.type === "waitTime") {
        let waitTimeTotal = 0;
        let dateDiff = 0;
        dataFiltered.forEach(data => {
          if (data.logs && data.logs.length) {
            let preparingLog = data.logs.find(e => e.order_status === "Preparing");
            let ReadyLog = data.logs.find(e => e.order_status === "Ready");
            if (preparingLog && ReadyLog) {
              dateDiff = moment(ReadyLog.created_at).diff(
                moment(preparingLog.created_at),
                "minutes"
              );
              waitTimeTotal = waitTimeTotal + dateDiff;
            }
          }
        });
        waitTimeAvg = waitTimeTotal ? waitTimeTotal / dataFiltered.length : 0;
        waitTimeData = [...waitTimeData, waitTimeAvg];
      }
    });

    if (payload.type === "scan") {
      commit("setScanData", countScannedData);
      let sum = countScannedData.reduce((a, b) => a + b, 0);
      let avg = parseFloat(sum / countScannedData.length).toFixed(2);
      commit("setAveScan", avg);
      let percentage = (avg / sum) * 100;
      percentage = percentage > 0 ? percentage : 0;
      commit("setScanPercentage", percentage);
    }

    if (payload.type === "waitTime") {
      commit("setWaitTimeData", waitTimeData);
      let sum = waitTimeData.reduce((a, b) => a + b, 0);
      let avg = parseFloat(sum / waitTimeData.length).toFixed(2);
      commit("setMinutes", avg);
    }
  },
  countByADay({ commit }, payload) {
    let countScannedData = [];
    let waitTimeData = [];

    payload.between.forEach(data => {
      let from = data.from;
      let to = data.to;
      let dataFiltered = payload.data.queues;
      let waitTimeAvg = 0;

      dataFiltered = dataFiltered.filter(data => {
        if (data.logs && data.logs.length) {
          let preparingLog = data.logs.find(e => e.order_status === "Preparing");
          if (preparingLog) {
            let x = moment(preparingLog.created_at,"DD-MM-YYYY");
            let y = moment(to,"DD-MM-YYYY");
            return moment(x).isSame(y);
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
      if (payload.type === "scan") {
        countScannedData = [...countScannedData, dataFiltered.length];
      }
      if (payload.type === "waitTime") {
        let waitTimeTotal = 0;
        let dateDiff = 0;
        dataFiltered.forEach(data => {
          if (data.logs && data.logs.length) {
            let preparingLog = data.logs.find(e => e.order_status === "Preparing");
            let ReadyLog = data.logs.find(e => e.order_status === "Ready");
            if (preparingLog && ReadyLog) {
              dateDiff = moment(ReadyLog.created_at).diff(
                moment(preparingLog.created_at),
                "minutes"
              );
              waitTimeTotal = waitTimeTotal + dateDiff;
            }
          }
        });
        waitTimeAvg = waitTimeTotal ? waitTimeTotal / dataFiltered.length : 0;
        waitTimeData = [...waitTimeData, waitTimeAvg];
      }
    });
    if (payload.type === "scan") {
      commit("setScanData", countScannedData);
      let sum = countScannedData.reduce((a, b) => a + b, 0);
      let avg = parseFloat(sum / countScannedData.length).toFixed(2);
      commit("setAveScan", avg);
      let percentage = (avg / sum) * 100;
      percentage = percentage > 0 ? percentage : 0;
      commit("setScanPercentage", percentage);
    }

    if (payload.type === "waitTime") {
      commit("setWaitTimeData", waitTimeData);
      let sum = waitTimeData.reduce((a, b) => a + b, 0);
      let avg = parseFloat(sum / waitTimeData.length).toFixed(2);
      commit("setMinutes", avg);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
