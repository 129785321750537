// import axios from "axios";
// import Vue from "vue";

const state = {
  input: {
    accountBalance: 0.0,
    data: {
      fundAmount: "",
      defaultPaymentMethod: false,
      isEnabled: false,
      defaultAmount: ""
    },
    loading: false,
    showAddFund: false,
    showNewCardModal: false,
    validators: {
      fundAmount: false,
      defaultAmount: false
    }
  }
};

const getters = {
  inputData(state) {
    return state.input.data;
  },
  fundAmount(state) {
    return state.input.data.fundAmount;
  },
  accountBalance(state) {
    return state.input.data.accountBalance;
  },
  defaultPaymentMethod(state) {
    return state.input.data.defaultPaymentMethod;
  },
  isEnabled(state) {
    return state.input.data.isEnabled;
  },
  isLoading(state) {
    return state.input.loading;
  },
  showAddFund(state) {
    return state.input.showAddFund;
  },
  showNewCardModal(state) {
    return state.input.showNewCardModal;
  },
  validators(state) {
    return state.input.validators;
  }
};
const mutations = {
  setInputData(state, data) {
    state.input.data[data.val] = data.e;
  },
  setFundAmount(state, data) {
    state.input.data.fundAmount = data;
  },
  setAccountBalance(state, data) {
    state.input.data.accountBalance = data;
  },
  setDefaultPaymentMethod(state, data) {
    state.input.data.defaultPaymentMethod = data;
  },
  setIsEnabled(state, data) {
    state.input.data.isEnabled = data;
  },
  setLoading(state, data) {
    state.input.loading = data;
  },
  setShowAddFund(state, data) {
    state.input.showAddFund = data;
  },
  setShowNewCardModal(state, data) {
    state.input.showNewCardModal = data;
  },
  setValidatorsFundAmount(state, data) {
    state.input.validators.fundAmount = data;
  }
};
const actions = {
  fetchSmsInitialData({ commit }) {
    commit("setLoading", true);
    let user = JSON.parse(localStorage.getItem("userData"));
    return new Promise((resolve, reject) => {
      commit("setFundAmount", 50);
      this.$http
        .get("vendor/settings/top-ups/" + user.id)
        .then(response => {
          response.data.data.forEach(data => {
            let key = Object.keys(data);
            switch (key[0]) {
              case "auto_top-up":
                commit("setIsEnabled", Boolean(Number(data["auto_top-up"])));
                break;
              case "default-payment":
                commit(
                  "setDefaultPaymentMethod",
                  Boolean(Number(data["default-payment"]))
                );
                break;
            }
          });
          this.$http
            .get("stripe/topup/balance/" + user.id)
            .then(response => {
              commit(
                "setAccountBalance",
                parseFloat(response.data.data.balance).toFixed(2)
              );
              commit("setLoading", false);
              resolve();
            })
            .catch(error => {
              let errorMessage = "Account Balance!";
              console.error("There was an error!", error);
              commit("setLoading", false);
              reject(errorMessage);
            });
        })
        .catch(error => {
          let errorMessage = "SMS Top up Settings!";
          console.error("There was an error!", error);
          commit("setLoading", false);
          reject(errorMessage);
        });
    });
  },
  updateSmsTopupData({ dispatch }, updateDetails) {
    let body = updateDetails;
    let user = JSON.parse(localStorage.getItem("userData"));
    this.$http
      .put("vendor/settings/" + user.id, body)
      .then(response => {
        console.log(response.data);
        dispatch("fetchSmsInitialData");
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
  },
  fetchAccountBalance({ commit }) {
    commit("setLoading", true);
    let user = JSON.parse(localStorage.getItem("userData"));
    this.$http
      .get("stripe/topup/balance/" + user.id)
      .then(response => {
        commit(
          "setAccountBalance",
          parseFloat(response.data.data.balance).toFixed(2)
        );
        commit("setLoading", false);
      })
      .catch(error => {
        console.error("There was an error!", error);
        commit("setLoading", false);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
