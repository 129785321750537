import moment from "moment";

const state = {
  input: {
    billingData: [],
    status: "undetermined",
    renewalDate: "undetermined",
    loading: false
  }
};

const getters = {
  inputData(state) {
    return state.input.billingData;
  },
  status(state) {
    return state.input.status;
  },
  renewalDate(state) {
    return state.input.renewalDate;
  },
  isLoading(state) {
    return state.input.loading;
  }
};
const mutations = {
  setInputData(state, data) {
    state.input.billingData = data;
  },
  setStatus(state, data) {
    state.input.status = data;
  },
  setRenewalDate(state, data) {
    state.input.renewalDate = data;
  },
  setLoading(state, data) {
    state.input.loading = data;
  }
};
const actions = {
  fetchBillingInitialData({ commit }) {
    commit("setLoading", true);
    let user = JSON.parse(localStorage.getItem("userData"));
    return new Promise((resolve, reject) => {
      this.$http
        .get("stripe/invoice/" + user.id)
        .then(response => {
          commit("setInputData", response.data.data);
          commit("setLoading", false);
          resolve();
        })
        .catch(error => {
          let errorMessage = error.message;
          console.error("There was an error!", error);
          commit("setLoading", false);
          reject(errorMessage);
        });
    });
  },
  fetchSubscriptionData({ commit }) {
    commit("setLoading", true);
    let user = JSON.parse(localStorage.getItem("userData"));
    return new Promise((resolve, reject) => {
      this.$http
        .get("stripe/subscription/status/" + user.id)
        .then(response => {
          let status =
            response.data.status === "active"
              ? "active"
              : response.data.status === "trialing"
              ? "on trial"
              : response.data.status === "unpaid" ||
                response.data.status === "incomplete" ||
                response.data.status === "ended"
              ? "expired"
              : response.data.status === "canceled"
              ? "cancelled"
              : "undetermined";
          commit("setStatus", status);
          commit(
            "setRenewalDate",
            moment(response.data.renew_date, "YYYY-MM-DD").format("MM/D/YYYY")
          );
          commit("setLoading", false);
          resolve();
        })
        .catch(error => {
          let errorMessage = error.message;
          console.error("There was an error!", error);
          commit("setLoading", false);
          reject(errorMessage);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
