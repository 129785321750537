const state = {
  form: {
    data: {
      username: "",
      password: ""
    },
    loading: false,
    errorMsg: "",
    validators: {
      username: false,
      password: false
    }
  }
};

const getters = {
  formData(state) {
    return state.form.data;
  },
  isLoading(state) {
    return state.form.loading;
  },
  errorMsg(state) {
    return state.form.errorMsg;
  },
  validators(state) {
    return state.form.validators;
  }
};
const mutations = {
  setFormData(state, data) {
    state.form.data[data.input] = data.value;
  },
  setLoading(state, data) {
    state.form.loading = data;
  },
  setErrorMsg(state, data) {
    state.form.errorMsg = data;
  },
  setValidatorUsername(state, data) {
    state.form.validators.username = data;
  },
  setValidatorPassword(state, data) {
    state.form.validators.password = data;
  }
};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
