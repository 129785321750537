<template>
  <header
    class="items-center justify-between h-24 bg-white x-sm:px-2 x-sm:py-2 sm:px-4 sm:py-2 md:px-20 md:py-4 md:flex x-sm:hidden sm:hidden"
  >
    <nav id="vendor-navbar" class="flex items-center justify-center w-full">
      <router-link
        class="flex flex-col items-center justify-center x-sm:px-2 x-sm:py-2 sm:px-2 sm:py-2 md:px-5 md:py-4 lg:px-10"
        :class="[$route.name === 'VendorBilling' ? activeClass : inactiveClass]"
        to="/vendor/billing"
      >
        <svg
          class="w-10 h-10 p-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36.273 41.455"
          stroke="currentColor"
        >
          <path
            id="invoices"
            d="M45.977,14.164A9.068,9.068,0,1,0,55.045,5.1,9.078,9.078,0,0,0,45.977,14.164Zm16.755,0a7.686,7.686,0,1,1-7.686-7.686A7.7,7.7,0,0,1,62.732,14.164ZM55.045,9.5a.691.691,0,0,1,.691.691v.292a2.076,2.076,0,0,1,1.382,1.954.691.691,0,1,1-1.382,0,.691.691,0,1,0-1.382,0v.28a.688.688,0,0,0,.485.659l.824.258a2.064,2.064,0,0,1,1.455,1.978v.28a2.076,2.076,0,0,1-1.641,2.027v.218a.691.691,0,1,1-1.382,0v-.4a2.074,2.074,0,0,1-1.123-1.841.691.691,0,1,1,1.382,0,.691.691,0,0,0,1.382,0v-.28a.688.688,0,0,0-.485-.659l-.824-.258a2.064,2.064,0,0,1-1.455-1.978v-.28a2.076,2.076,0,0,1,1.382-1.954v-.292A.691.691,0,0,1,55.045,9.5ZM66.273,27.809v8.809a4.842,4.842,0,0,1-4.836,4.836h-26.6A4.842,4.842,0,0,1,30,36.618V2.764A2.767,2.767,0,0,1,32.764,0H55.218a2.767,2.767,0,0,1,2.764,2.764.691.691,0,1,1-1.382,0,1.383,1.383,0,0,0-1.382-1.382H32.764a1.383,1.383,0,0,0-1.382,1.382V36.618a3.458,3.458,0,0,0,3.455,3.455h23.22A4.821,4.821,0,0,1,56.6,36.618v-10.8a.691.691,0,1,1,1.382,0v10.8a3.455,3.455,0,1,0,6.909,0V27.809a1.383,1.383,0,0,0-1.382-1.382H60.573a.691.691,0,1,1,0-1.382h2.936A2.767,2.767,0,0,1,66.273,27.809ZM36.045,18.568a.691.691,0,0,1,.691-.691h5.873a.691.691,0,0,1,0,1.382H36.736A.691.691,0,0,1,36.045,18.568Zm0-7.773a.691.691,0,0,1,.691-.691h5.873a.691.691,0,0,1,0,1.382H36.736A.691.691,0,0,1,36.045,10.8ZM51.936,26.341a.691.691,0,0,1-.691.691H36.736a.691.691,0,1,1,0-1.382H51.245A.691.691,0,0,1,51.936,26.341Zm0,7.255a.691.691,0,0,1-.691.691H36.736a.691.691,0,1,1,0-1.382H51.245A.691.691,0,0,1,51.936,33.6Z"
            transform="translate(-30)"
          />
        </svg>

        <span class="mx-auto text-xs">Billing</span>
      </router-link>

      <router-link
        class="flex flex-col items-center justify-center x-sm:px-2 x-sm:py-2 sm:px-2 sm:py-2 md:px-5 md:py-4 lg:px-10"
        :class="[
          $route.name === 'VendorSmstopup' ? activeClass : inactiveClass
        ]"
        to="/vendor/sms-top-up"
      >
        <svg
          fill="currentColor"
          class="w-10 h-10 p-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 23.854 41.455"
        >
          <g id="thermometer" transform="translate(-91.071)">
            <g
              id="Group_217"
              data-name="Group 217"
              transform="translate(91.071)"
            >
              <g id="Group_216" data-name="Group 216" transform="translate(0)">
                <path
                  id="Path_201"
                  data-name="Path 201"
                  d="M106.615,22.847V5.438a5.438,5.438,0,1,0-10.877,0v17.4a10.1,10.1,0,0,0,5.411,18.607l.054.008a10.1,10.1,0,0,0,5.411-18.607Zm-9.686,15.92a8.558,8.558,0,0,1,0-14.822.773.773,0,0,0,.387-.673V5.438a3.892,3.892,0,1,1,7.785,0V23.273a.773.773,0,0,0,.387.673,8.558,8.558,0,0,1-8.558,14.822Z"
                  transform="translate(-91.071)"
                />
              </g>
            </g>
            <g
              id="Group_219"
              data-name="Group 219"
              transform="translate(95.847 13.3)"
            >
              <g id="Group_218" data-name="Group 218" transform="translate(0)">
                <path
                  id="Path_202"
                  data-name="Path 202"
                  d="M146.619,150.14V138.413a.773.773,0,1,0-1.546,0V150.14a5.357,5.357,0,1,0,1.546,0Zm-.773,9.076a3.8,3.8,0,0,1,0-7.591h0a3.8,3.8,0,0,1,0,7.591Z"
                  transform="translate(-140.489 -137.64)"
                />
              </g>
            </g>
            <g
              id="Group_221"
              data-name="Group 221"
              transform="translate(108.548 5.315)"
            >
              <g id="Group_220" data-name="Group 220">
                <path
                  id="Path_203"
                  data-name="Path 203"
                  d="M277.534,55H272.7a.773.773,0,0,0,0,1.546h4.832a.773.773,0,0,0,0-1.546Z"
                  transform="translate(-271.929 -55)"
                />
              </g>
            </g>
            <g
              id="Group_223"
              data-name="Group 223"
              transform="translate(109.514 9.18)"
            >
              <g id="Group_222" data-name="Group 222" transform="translate(0)">
                <path
                  id="Path_204"
                  data-name="Path 204"
                  d="M285.539,95H282.7a.773.773,0,1,0,0,1.546h2.837a.773.773,0,0,0,0-1.546Z"
                  transform="translate(-281.929 -95)"
                />
              </g>
            </g>
            <g
              id="Group_225"
              data-name="Group 225"
              transform="translate(108.548 13.045)"
            >
              <g id="Group_224" data-name="Group 224">
                <path
                  id="Path_205"
                  data-name="Path 205"
                  d="M277.534,135H272.7a.773.773,0,0,0,0,1.546h4.832a.773.773,0,1,0,0-1.546Z"
                  transform="translate(-271.929 -135)"
                />
              </g>
            </g>
            <g
              id="Group_227"
              data-name="Group 227"
              transform="translate(109.514 16.91)"
            >
              <g id="Group_226" data-name="Group 226" transform="translate(0)">
                <path
                  id="Path_206"
                  data-name="Path 206"
                  d="M285.539,175H282.7a.773.773,0,0,0,0,1.546h2.837a.773.773,0,1,0,0-1.546Z"
                  transform="translate(-281.929 -175)"
                />
              </g>
            </g>
            <g
              id="Group_229"
              data-name="Group 229"
              transform="translate(108.548 20.776)"
            >
              <g id="Group_228" data-name="Group 228">
                <path
                  id="Path_207"
                  data-name="Path 207"
                  d="M277.534,215H272.7a.773.773,0,0,0,0,1.546h4.832a.773.773,0,1,0,0-1.546Z"
                  transform="translate(-271.929 -215)"
                />
              </g>
            </g>
          </g>
        </svg>

        <span class="mx-auto text-xs">Sms Top Up</span>
      </router-link>

      <router-link
        class="flex flex-col items-center justify-center x-sm:px-2 x-sm:py-2 sm:px-2 sm:py-2 md:px-5 md:py-4 lg:px-10"
        :class="[
          $route.name === 'VendorStatistics' ? activeClass : inactiveClass
        ]"
        to="/vendor/statistics"
      >
        <svg
          class="w-10 h-10 p-1"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 39.252 41.455"
        >
          <g id="bar-chart" transform="translate(-13.601)">
            <g
              id="Group_211"
              data-name="Group 211"
              transform="translate(13.601 11.874)"
            >
              <g id="Group_210" data-name="Group 210">
                <path
                  id="Path_199"
                  data-name="Path 199"
                  d="M52.043,174.615H50.268V148.831a2.179,2.179,0,0,0-2.177-2.177H44.122a2.179,2.179,0,0,0-2.177,2.177v25.785H37.436v-14.1a2.179,2.179,0,0,0-2.177-2.177H31.29a2.179,2.179,0,0,0-2.177,2.177v14.1H24.6v-7.3a2.179,2.179,0,0,0-2.177-2.177H18.459a2.179,2.179,0,0,0-2.177,2.177v7.3H14.411a.81.81,0,0,0,0,1.619H52.043a.81.81,0,1,0,0-1.619Zm-29.058,0H17.9v-7.3a.558.558,0,0,1,.557-.557h3.969a.558.558,0,0,1,.557.557Zm12.832,0H30.733v-14.1a.558.558,0,0,1,.557-.557H35.26a.558.558,0,0,1,.557.557Zm12.832,0H43.565V148.831a.558.558,0,0,1,.557-.557h3.969a.558.558,0,0,1,.557.557v25.785Z"
                  transform="translate(-13.601 -146.654)"
                />
              </g>
            </g>
            <g
              id="Group_213"
              data-name="Group 213"
              transform="translate(20.543)"
            >
              <g id="Group_212" data-name="Group 212">
                <path
                  id="Path_200"
                  data-name="Path 200"
                  d="M129.068.851A.807.807,0,0,0,128.219,0l-.039,0h-4.9a.81.81,0,0,0,0,1.619h3.025l-8,8-3.018-3.018a.81.81,0,0,0-1.145,0L99.583,21.16a.81.81,0,0,0,1.145,1.145L114.711,8.322l3.018,3.018a.81.81,0,0,0,1.145,0l8.577-8.576V5.5a.81.81,0,1,0,1.619,0V.891C129.07.877,129.069.865,129.068.851Z"
                  transform="translate(-99.346)"
                />
              </g>
            </g>
            <g
              id="Group_215"
              data-name="Group 215"
              transform="translate(17.814 23.748)"
            >
              <g id="Group_214" data-name="Group 214">
                <circle
                  id="Ellipse_16"
                  data-name="Ellipse 16"
                  cx="0.81"
                  cy="0.81"
                  r="0.81"
                />
              </g>
            </g>
          </g>
        </svg>

        <span class="mx-auto text-xs">Usage Statistics</span>
      </router-link>

      <router-link
        class="flex flex-col items-center justify-center x-sm:px-2 x-sm:py-2 sm:px-2 sm:py-2 md:px-5 md:py-4 lg:px-10"
        :class="[$route.name === 'VendorQueue' ? activeClass : inactiveClass]"
        to="/vendor/queue"
      >
        <svg
          class="w-10 h-10 p-1"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 31.076 41.455"
        >
          <g
            id="Group_230"
            data-name="Group 230"
            transform="translate(-116 -233.297)"
          >
            <path
              id="Path_208"
              data-name="Path 208"
              d="M140.48,244.588a9.4,9.4,0,0,1,6.58,2.687,6.677,6.677,0,0,0-6.58-5.695H122.722a6.677,6.677,0,0,0-6.58,5.695,9.4,9.4,0,0,1,6.58-2.687Z"
              transform="translate(-0.063 -3.687)"
            />
            <path
              id="Path_209"
              data-name="Path 209"
              d="M141.07,235.407a8.325,8.325,0,0,1,5.831,2.381c-.418-2.843-2.877-4.492-5.831-4.492H125.334c-2.955,0-5.413,1.648-5.832,4.492a8.327,8.327,0,0,1,5.832-2.381Z"
              transform="translate(-1.559)"
            />
            <path
              id="Path_210"
              data-name="Path 210"
              d="M140.417,252H122.659A6.679,6.679,0,0,0,116,258.659v17.758a6.679,6.679,0,0,0,6.659,6.659h17.758a6.679,6.679,0,0,0,6.659-6.659V258.659A6.679,6.679,0,0,0,140.417,252Zm5.086,24.417a5.091,5.091,0,0,1-5.086,5.086H122.659a5.091,5.091,0,0,1-5.086-5.086V258.659a5.091,5.091,0,0,1,5.086-5.086h17.758a5.091,5.091,0,0,1,5.086,5.086Z"
              transform="translate(0 -8.324)"
            />
          </g>
        </svg>

        <span class="mx-auto text-xs">Queue Screen</span>
      </router-link>

      <router-link
        class="flex flex-col items-center justify-center x-sm:px-2 x-sm:py-2 sm:px-2 sm:py-2 md:px-5 md:py-4 lg:px-10"
        :class="[
          $route.name === 'VendorNotifications' ? activeClass : inactiveClass
        ]"
        to="/vendor/notifications"
      >
        <svg
          fill="currentColor"
          class="w-10 h-10 p-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 38.337 38.337"
        >
          <g
            id="Group_231"
            data-name="Group 231"
            transform="translate(-116 -239.393)"
          >
            <path
              id="Path_214"
              data-name="Path 214"
              d="M145.708,276.587a5.127,5.127,0,0,1-5.121,5.121H122.706a5.127,5.127,0,0,1-5.121-5.121V258.706a5.127,5.127,0,0,1,5.121-5.121h11.581a11.387,11.387,0,0,1,.123-1.584h-11.7A6.725,6.725,0,0,0,116,258.706v17.882a6.725,6.725,0,0,0,6.706,6.706h17.882a6.725,6.725,0,0,0,6.706-6.706v-11.7a11.391,11.391,0,0,1-1.584.123Z"
              transform="translate(0 -5.562)"
            />
            <path
              id="Path_215"
              data-name="Path 215"
              d="M162.352,239.393a8.629,8.629,0,1,0,8.629,8.629A8.628,8.628,0,0,0,162.352,239.393Zm1.495,13.065h-2.113v-6.338h-1.186v-1.51l3.3-.744Z"
              transform="translate(-16.644)"
            />
          </g>
        </svg>

        <span class="mx-auto text-xs">Notifications</span>
      </router-link>

      <router-link
        class="flex flex-col items-center justify-center x-sm:px-2 x-sm:py-2 sm:px-2 sm:py-2 md:px-5 md:py-4 lg:px-10"
        :class="[
          $route.name === 'VendorMarketing' ? activeClass : inactiveClass
        ]"
        to="/vendor/marketing"
      >
        <svg
          fill="currentColor"
          class="w-10 h-10 p-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 44.624 41.202"
        >
          <g id="_x30_6_chat_bubble" transform="translate(0 -19.631)">
            <path
              id="Path_230"
              data-name="Path 230"
              d="M41.627,27.011a19.126,19.126,0,0,0-15.435-7.38.655.655,0,0,0,0,1.309,17.832,17.832,0,0,1,14.382,6.849l.007.009a13.614,13.614,0,0,1,2.734,8.121c0,5.546-3.435,10.3-8.374,12.875a14.666,14.666,0,0,0,1.853-7.977c-.48-8.879-8.871-15.465-18.4-15.465a20.4,20.4,0,0,0-5,.623,17.928,17.928,0,0,1,9.857-4.813.655.655,0,0,0-.2-1.295,19.321,19.321,0,0,0-9.857,4.5,17.094,17.094,0,0,0-2.236,2.379A18.654,18.654,0,0,0,5.422,30.1,15.272,15.272,0,0,0,0,41.633a14.852,14.852,0,0,0,2.681,8.458.655.655,0,1,0,1.078-.744,13.549,13.549,0,0,1-2.449-7.715c0-8.247,7.67-14.957,17.1-14.957,8.94,0,16.5,6.107,17.064,14.023C36,48.213,30.043,54.664,22.2,56.219c-.059.012.474-.16-9.593,3.125l.873-2.675a.655.655,0,0,0-.441-.832,17.616,17.616,0,0,1-7.1-3.966.655.655,0,0,0-.892.958A18.843,18.843,0,0,0,12.033,56.9l-.859,2.633a.993.993,0,0,0,1.253,1.253L22.5,57.5A18.707,18.707,0,0,0,33.585,50.84c6.509-2.523,11.039-8.252,11.039-14.921a14.889,14.889,0,0,0-3-8.908Z"
              transform="translate(0 0)"
            />
            <g
              id="Group_287"
              data-name="Group 287"
              transform="translate(15.558 38.886)"
            >
              <path
                id="Path_231"
                data-name="Path 231"
                d="M181.36,246.256a2.85,2.85,0,1,1,2.85-2.85A2.853,2.853,0,0,1,181.36,246.256Zm0-4.39a1.54,1.54,0,1,0,1.54,1.54A1.542,1.542,0,0,0,181.36,241.865Z"
                transform="translate(-178.51 -240.556)"
              />
            </g>
            <g
              id="Group_288"
              data-name="Group 288"
              transform="translate(6.632 38.886)"
            >
              <path
                id="Path_232"
                data-name="Path 232"
                d="M78.949,246.256a2.85,2.85,0,1,1,2.85-2.85A2.853,2.853,0,0,1,78.949,246.256Zm0-4.39a1.54,1.54,0,1,0,1.54,1.54A1.542,1.542,0,0,0,78.949,241.865Z"
                transform="translate(-76.099 -240.556)"
              />
            </g>
            <g
              id="Group_289"
              data-name="Group 289"
              transform="translate(24.484 38.886)"
            >
              <path
                id="Path_233"
                data-name="Path 233"
                d="M283.772,246.256a2.85,2.85,0,1,1,2.85-2.85A2.853,2.853,0,0,1,283.772,246.256Zm0-4.39a1.54,1.54,0,1,0,1.541,1.54A1.542,1.542,0,0,0,283.772,241.865Z"
                transform="translate(-280.922 -240.556)"
              />
            </g>
          </g>
        </svg>

        <span class="mx-auto text-xs">Marketing</span>
      </router-link>
    </nav>
  </header>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const activeClass = ref("text-qgo-primary-bg");
    const inactiveClass = ref("text-gray-400 hover:text-qgo-primary-bg");

    return {
      activeClass,
      inactiveClass
    };
  },
  data() {
    return {};
  },
  methods: {}
});
</script>
