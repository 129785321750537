<template>
  <div class="flex h-screen bg-gray-200 font-roboto">
    <Sidebar />

    <div class="flex flex-col flex-1">
      <Header />
      <Navbar />

      <main class="flex-1 bg-gray-200">
        <div class="h-full">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Sidebar from "@/components/Sidebar.vue";
import Header from "@/components/Header.vue";
import Navbar from "@/components/Navbar.vue";

export default defineComponent({
  components: {
    Header,
    Sidebar,
    Navbar
  }
});
</script>
