import { createLogger } from "vuex";

const debug = process.env.NODE_ENV !== "production";

/**
 * All core plugins goes here
 * @returns {[<S>(store: Store<S>) => void]}
 */
export function corePlugins() {
  /** Instantiate all core plugins or middlewares here */
  const middlewares = [];

  if (debug) {
    middlewares.push(createLogger());
  }

  return middlewares;
}

/**
 * All feature plugins goes here
 * @returns {*[]}
 */
export function featurePlugins() {
  /** Instantiate all feature's plugins here */
  return [];
}
