<template>
  <header
    class="
      flex
      items-center
      justify-between
      h-20
      py-4
      bg-gray-200
      x-sm:px-4
      sm:px-8
      md:px-16
      lg:px-24
    "
  >
    <div class="flex items-center justify-between w-full">
      <div class="flex items-center">
        <button
          id="sidenav-button"
          @click="isOpen = true"
          class="text-qgo-primary-bg focus:outline-none md:hidden"
        >
          <svg
            class="w-6 h-6"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6H20M4 12H20M4 18H11"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <div class="flex items-center mx-2">
          <!-- Q&Go Logo in SVG format -->
          <svg
            class="px-2"
            xmlns="http://www.w3.org/2000/svg"
            width="83.047"
            height="21.953"
            viewBox="0 0 83.047 21.953"
          >
            <g id="Group_13" data-name="Group 13" transform="translate(0 0.5)">
              <g id="Group_8" data-name="Group 8" transform="translate(0 0)">
                <g id="Group_7" data-name="Group 7">
                  <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M366,416.651l2.511-2.7-2.933-2.8a16.528,16.528,0,0,1-2.616,2.828l-2.511-2.643c2.061-1.031,3.7-2.3,3.7-4.783,0-3.409-3.066-5.418-6.237-5.418-3.2,0-6.184,2.035-6.184,5.47a5.442,5.442,0,0,0,1.427,3.462l.423.581-.475.212c-2.511,1.11-4.281,2.564-4.281,5.55,0,3.647,2.96,5.708,6.4,5.708a12.575,12.575,0,0,0,6.818-2.378l2.248,2.38,6.424-.051-4.946-5.184Zm-9.566.819a2.053,2.053,0,0,1-2.246-1.823c0-1.11,1.031-1.85,1.876-2.379l3.145,3.383A5.658,5.658,0,0,1,356.433,417.47Zm1.771-8.536c-.634-.713-1.559-1.665-1.559-2.642a1.6,1.6,0,0,1,1.665-1.639,1.751,1.751,0,0,1,1.744,1.85A3.114,3.114,0,0,1,358.2,408.934Z"
                    transform="translate(-325.64 -401.138)"
                    fill="#071d22"
                  />
                  <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M269.988,411.5c0-6.2-4.609-10.231-10.56-10.231-5.5,0-10.56,4.052-10.56,9.825a10.153,10.153,0,0,0,10.56,10.534,9.868,9.868,0,0,0,5.242-1.241l1.519,1.8,5.273.007-3.931-3.982A10.392,10.392,0,0,0,269.988,411.5Zm-6.052,2.837-1.342-1.393-5.253.014,3.632,3.607a4.556,4.556,0,0,1-1.519.254,5.492,5.492,0,0,1-5.419-5.7,5.361,5.361,0,0,1,5.419-5.039,5.487,5.487,0,0,1,5.369,5.445A4.931,4.931,0,0,1,263.935,414.333Z"
                    transform="translate(-248.868 -401.236)"
                    fill="#071d22"
                  />
                </g>
              </g>
              <g
                id="Group_11"
                data-name="Group 11"
                transform="translate(44.372 0)"
              >
                <path
                  id="Union_1"
                  data-name="Union 1"
                  d="M0,10.477A10.628,10.628,0,0,1,10.774,0a10.924,10.924,0,0,1,6.3,1.979A13.437,13.437,0,0,0,14.831,5.9a5.883,5.883,0,0,0-4.058-1.634,6.115,6.115,0,0,0-6.016,6.209,6.115,6.115,0,0,0,6.016,6.209,5.886,5.886,0,0,0,4.056-1.628,6.237,6.237,0,0,0,1.713-2.82H9.619l1.031-1.863L9.619,8.512h17.36V6.838L29.1,8.607l2.125,1.768L29.1,12.143l-2.125,1.768V12.238H21.393a10.446,10.446,0,0,1-4.329,6.743,10.925,10.925,0,0,1-6.29,1.972A10.628,10.628,0,0,1,0,10.477Z"
                  transform="translate(0 0)"
                  fill="#009a74"
                  stroke="rgba(0,0,0,0)"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <g
                  id="Group_10"
                  data-name="Group 10"
                  transform="translate(17.32)"
                >
                  <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M525.446,401.138a10.7,10.7,0,0,0-10.582,8.512h4.875a6.038,6.038,0,0,1,5.706-4.244,6.212,6.212,0,0,1,0,12.418,5.886,5.886,0,0,1-4.058-1.63,13.343,13.343,0,0,1-2.239,3.921,10.924,10.924,0,0,0,6.3,1.977,10.481,10.481,0,1,0,0-20.953Z"
                    transform="translate(-514.864 -401.138)"
                    fill="#071d22"
                  />
                </g>
              </g>
            </g>
          </svg>
          <!-- End Q&Go Logo in SVG format -->

          <span
            class="
              px-2
              text-2xl
              font-semibold
              border-l-2 border-qgo-primary-bg
              text-qgo-primary-bg
            "
            >Vendor</span
          >
        </div>
      </div>
      <div class="flex items-center">
        <div class="flex flex-col">
          <div class="flex p-1">
            <span v-if="vendorNamee" class="px-1"> Hey, {{ vendorNamee }} </span>
            <span v-else class="px-1"> Hey, {{ vendorName }} </span>
            <button
              @click="signOut"
              class="
                px-1
                text-qgo-primary-bg
                focus:outline-none
                x-sm:hidden
                md:flex
              "
            >
              Sign Out
            </button>
          </div>
          <div class="flex justify-center p-1">
            <button
              @click="$router.push({ name: 'VendorProfile' })"
              class="flex justify-center focus:outline-none mr-4"
            >
              <svg
                class="w-4 h-4 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12.055 10.718"
              >
                <path
                  id="edit"
                  d="M8.42,7.145l.67-.67a.168.168,0,0,1,.287.119V9.638a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V2.271a1,1,0,0,1,1-1H6.729a.169.169,0,0,1,.119.287l-.67.67a.166.166,0,0,1-.119.048H1V9.638H8.372V7.263A.165.165,0,0,1,8.42,7.145ZM11.7,2.922l-5.5,5.5-1.892.209a.865.865,0,0,1-.954-.954l.209-1.892,5.5-5.5a1.222,1.222,0,0,1,1.731,0l.9.9a1.226,1.226,0,0,1,0,1.733ZM9.63,3.568,8.414,2.352,4.525,6.243,4.372,7.61l1.367-.153ZM10.986,1.9l-.9-.9a.219.219,0,0,0-.31,0l-.647.647,1.216,1.216.647-.647A.223.223,0,0,0,10.986,1.9Z"
                  transform="translate(0 0.075)"
                  fill="currentColor"
                />
              </svg>
              <span class="text-xs text-gray-400">EDIT</span>
            </button>
            <button
              @click="$router.push({ name: 'ChangePassword' })"
              class="flex justify-center focus:outline-none"
            >
              <svg
                class="w-4 h-4 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12.055 10.718"
              >
                <path
                  id="edit"
                  d="M8.42,7.145l.67-.67a.168.168,0,0,1,.287.119V9.638a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V2.271a1,1,0,0,1,1-1H6.729a.169.169,0,0,1,.119.287l-.67.67a.166.166,0,0,1-.119.048H1V9.638H8.372V7.263A.165.165,0,0,1,8.42,7.145ZM11.7,2.922l-5.5,5.5-1.892.209a.865.865,0,0,1-.954-.954l.209-1.892,5.5-5.5a1.222,1.222,0,0,1,1.731,0l.9.9a1.226,1.226,0,0,1,0,1.733ZM9.63,3.568,8.414,2.352,4.525,6.243,4.372,7.61l1.367-.153ZM10.986,1.9l-.9-.9a.219.219,0,0,0-.31,0l-.647.647,1.216,1.216.647-.647A.223.223,0,0,0,10.986,1.9Z"
                  transform="translate(0 0.075)"
                  fill="currentColor"
                />
              </svg>
              <span class="text-xs text-gray-400">CHANGE PASSWORD</span>
            </button>
          </div>
        </div>
        <div class="md:flex x-sm:hidden">
          <img
            v-if="profilePic"
            alt="logo"
            :src="profilePic"
            class="w-12 h-12 mx-2 rounded-full"
          />
          <img
            v-else
            alt="logo"
            :src="logo"
            class="w-12 h-12 mx-2 rounded-full"
          />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { defineComponent } from "vue";
import { useSidebar } from "@/hooks/useSidebar";

export default defineComponent({
  setup() {
    const { isOpen } = useSidebar();

    return {
      isOpen,
    };
  },
  data() {
    return {
      vendorName: "Zeke's Hut",
      logo: "",
    };
  },
  methods: {
    signOut() {
      localStorage.clear();
      this.$http
        .post("auth/logout", {})
        .then((response) => {
          this.$router.push({ name: "VendorSignin" });
        })
        .catch((error) => {
          this.$router.push({ name: "VendorSignin" });
        });
    },
  },
  computed: {
    profilePic() {
      return this.$store.getters["profile/vendorLogo"];
    },
    vendorNamee() {
      return this.$store.getters["profile/VendorName"];
    },
  },
  created() {
    let user = JSON.parse(localStorage.getItem("userData"));
    
    if (user && user.email) {
      this.userId = user.id;
      this.vendorName = user.name;
      this.logo = user.logo;
    }
  },
  mounted() {},
});
</script>
