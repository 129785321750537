const state = {
  input: {
    notificationData: {
      smsMessage: false,
      pushNotification: false,
      emailNotifications: false
    },
    reminderText: "Remind once",
    reminderPromptModal: false,
    loading: false
  }
};

const getters = {
  inputData(state) {
    return state.input.notificationData;
  },
  reminderText(state) {
    return state.input.reminderText;
  },
  reminderPromptModal(state) {
    return state.input.reminderPromptModal;
  },
  isLoading(state) {
    return state.input.loading;
  }
};
const mutations = {
  setInputData(state, data) {
    state.input.notificationData[data.name] = data.value;
  },
  setReminderText(state, data) {
    state.input.reminderText = data;
  },
  setReminderPromptModal(state, data) {
    state.input.reminderPromptModal = data;
  },
  setLoading(state, data) {
    state.input.loading = data;
  },
  setSmsMessage(state, data) {
    state.input.notificationData.smsMessage = data;
  },
  setPushNotification(state, data) {
    state.input.notificationData.pushNotification = data;
  },
  setEmailNotifications(state, data) {
    state.input.notificationData.emailNotifications = data;
  }
};
const actions = {
  fetchNotificationInitialData({ commit }) {
    commit("setLoading", true);
    let user = JSON.parse(localStorage.getItem("userData"));
    return new Promise((resolve, reject) => {
      this.$http
        .get("vendor/settings/notifications/" + user.id)
        .then(response => {
          response.data.data.forEach(data => {
            let key = Object.keys(data);
            switch (key[0]) {
              case "sms_messages":
                commit("setSmsMessage", Boolean(Number(data.sms_messages)));
                break;
              case "push_notifications":
                commit(
                  "setPushNotification",
                  Boolean(Number(data.push_notifications))
                );
                break;
              case "email_notifications":
                commit(
                  "setEmailNotifications",
                  Boolean(Number(data.email_notifications))
                );
                break;
            }
          });
          commit("setLoading", false);
          resolve();
        })
        .catch(error => {
          let errorMessage = error.message;
          console.error("There was an error!", error);
          commit("setLoading", false);
          reject(errorMessage);
        });
    });
  },
  updateNotificationData({ commit, dispatch }, data) {
    let user = JSON.parse(localStorage.getItem("userData"));
    commit("setLoading", true);
    let body = data;
    return new Promise((resolve, reject) => {
      this.$http
        .put("vendor/settings/" + user.id, body)
        .then(response => {
          console.log(response.data);
          commit("setLoading", false);
          let name =
            data.name === "sms_messages"
              ? "Sms Messages"
              : data.name === "push_notifications"
              ? "Push Notifications"
              : data.name === "email_notifications"
              ? "Email Notifications"
              : "n/a";
          let respondData = {
            name: name,
            value: data.value ? "Enabled" : "Disabled"
          };
          resolve(respondData);
          dispatch("fetchNotificationInitialData");
        })
        .catch(error => {
          let errorMessage = error.message;
          console.error("There was an error!", error);
          commit("setLoading", false);
          reject(errorMessage);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
