import queues from "./queues";
import smsTopUp from "./vendor/SmsTopUp";
import notifications from "./vendor/notifications";
import queue from "./vendor/queue";
import profile from "./vendor/profile";
import marketing from "./vendor/marketing";
import billing from "./vendor/billing";
import statistics from "./vendor/statistics";

export default {
  queues,
  smsTopUp,
  notifications,
  queue,
  profile,
  marketing,
  billing,
  statistics
};
